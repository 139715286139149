<template>
    <div class="dashboard">

        <div class="dash-wrap"> 

            <div class="sidebar">
                <div class="filter">
                    <div class="wrap2">
                        <div class="item">
                            <label for="">Año</label>
                            <multiselect v-model="year" :options="yearOptions" :multiple="true" :max-height="160" placeholder="" open-direction="bottom"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" >
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} años seleccionados</span></template>
                            </multiselect>
                        </div>
                        <div class="item">
                            <vs-select label="Filtro Fecha" v-model="dateFilter">
                                <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in dateTypes" />
                            </vs-select>
                        </div>
                        <div class="item">
                            <label for="">Agente</label>
                            <multiselect v-model="vendors" :options="consultants" :multiple="true" :max-height="160" placeholder="" open-direction="bottom"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="NAME" track-by="INITIALS">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} agentes seleccionados</span></template>
                            </multiselect>
                        </div>
                        <div class="item">
                            <search-supplier flagType="Agencies" @supplier="findSupplier" v-click-outside="closeSuggest" :openSuggestions="openSuggestions"></search-supplier>
                        </div>
                        
                        <div class="tags">
                            <div class="wrapper-tags">
                                <template v-for="(item, idx) in suppliers">
                                    <vs-chip :key="idx" transparent color="primary" closable @click="removeSupplier(item, 1)">{{ item.CODE }}</vs-chip>
                                </template>
                           </div>
                        </div>
                    </div>
                    <div class="item">
                        <vs-button color="primary" type="filled" @click="filterDashboard()">Filtrar</vs-button>
                    </div>
                </div>
                <div class="counter">
                    <div class="item">
                        <span>{{ $n(totalSales, 'currency') }}</span>
                        <p>Total</p>
                    </div>
                    <div class="item">
                        <span>{{ $n(totalDirect, 'currency') }}</span>
                        <p>Total Directo</p>
                    </div>
                    <div class="item">
                        <span>{{ $n(totalIndirect, 'currency') }}</span>
                        <p>Total Indirecto</p>
                    </div>
                </div>
            </div>
            
            <div class="content">
                <div class="widget">
                    <div class="subtitle"><vs-switch v-model="showLineChartDirect"/>Venta Directa</div>
                    <line-chart :key="`line-${key}`" :labels="monthAbr" title="s"
                        :dataChart="salesDirect.values" :height="250" v-if="showLineChartDirect"></line-chart>
                    <bar-chart :key="key" :labels="monthAbr" title="s" 
                        :dataChart="salesDirect.values" :height="250" v-if="kpisDirect.length > 0 && !showLineChartDirect"></bar-chart>
                 </div>
                 <div class="widget">
                    <div class="subtitle"><vs-switch v-model="showLineChartIndirect"/>Venta Indirecta</div>
                    <line-chart :key="`line-${key}`" :labels="monthAbr" title="s"
                        :dataChart="salesIndirect.values" :height="250" v-if="showLineChartIndirect"></line-chart>
                    <bar-chart :key="key" :labels="monthAbr" title="s" 
                        :dataChart="salesIndirect.values" :height="250" v-if="kpisIndirect.length > 0 && !showLineChartIndirect"></bar-chart>
                 </div>
                <div class="widget gap-2">
                    <div class="subtitle">
                        <vs-switch v-model="showLineChart"/>
                        Venta
                    </div>
                    <line-chart :key="`line-${key}`" :labels="monthAbr" title="s"
                        :dataChart="comparative.values" :height="350" v-if="showLineChart"></line-chart>
                    <bar-chart :key="key" :labels="monthAbr" title="s" 
                        :dataChart="comparative.values" :height="350" v-if="kpis.length > 0 && !showLineChart"></bar-chart>
                </div>
            <div class="table-wrapper with-border gap-2 widget">

                <div class="subtitle"><vs-switch v-model="showMarginYear"/>Show Margin %</div>
                <vs-table :data="kpis">
                    <template slot="thead">
                        <vs-th sort-key="YEAR" class="center">Año</vs-th>
                        <vs-th sort-key="January">Enero</vs-th>
                        <vs-th sort-key="February">Febrero</vs-th>
                        <vs-th sort-key="March">Marzo</vs-th>
                        <vs-th sort-key="April">Abril</vs-th>
                        <vs-th sort-key="May">Mayo</vs-th>
                        <vs-th sort-key="June">Junio</vs-th>
                        <vs-th sort-key="July">Julio</vs-th>
                        <vs-th sort-key="August">Agosto</vs-th>
                        <vs-th sort-key="September">Septiembre</vs-th>
                        <vs-th sort-key="October">Octubre</vs-th>
                        <vs-th sort-key="November">Noviembre</vs-th>
                        <vs-th sort-key="December">Diciembre</vs-th>
                        <vs-th sort-key="TOTAL">Total</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                        <template :data="tr"  v-for="(tr, indextr) in data">
                            <vs-tr :key="indextr">
                                <vs-td :data="data[indextr].YEAR">     {{ data[indextr].YEAR }} </vs-td>
                                <vs-td :data="data[indextr].January">  {{ $n(data[indextr].January , !showMarginYear ? 'currency' : 'percent' ) }} </vs-td>
                                <vs-td :data="data[indextr].February"> {{ $n(data[indextr].February, !showMarginYear ? 'currency' : 'percent') }} </vs-td>
                                <vs-td :data="data[indextr].March">    {{ $n(data[indextr].March   , !showMarginYear ? 'currency' : 'percent') }} </vs-td>
                                <vs-td :data="data[indextr].April">    {{ $n(data[indextr].April   , !showMarginYear ? 'currency' : 'percent') }} </vs-td>
                                <vs-td :data="data[indextr].May">      {{ $n(data[indextr].May     , !showMarginYear ? 'currency' : 'percent') }} </vs-td>
                                <vs-td :data="data[indextr].June">     {{ $n(data[indextr].June    , !showMarginYear ? 'currency' : 'percent') }} </vs-td>
                                <vs-td :data="data[indextr].July">     {{ $n(data[indextr].July    , !showMarginYear ? 'currency' : 'percent') }} </vs-td>
                                <vs-td :data="data[indextr].August">   {{ $n(data[indextr].August  , !showMarginYear ? 'currency' : 'percent') }} </vs-td>
                                <vs-td :data="data[indextr].September">{{ $n(data[indextr].September, !showMarginYear ? 'currency' : 'percent') }} </vs-td>
                                <vs-td :data="data[indextr].October">  {{ $n(data[indextr].October , !showMarginYear ? 'currency' : 'percent') }} </vs-td>
                                <vs-td :data="data[indextr].November"> {{ $n(data[indextr].November, !showMarginYear ? 'currency' : 'percent') }} </vs-td>
                                <vs-td :data="data[indextr].December"> {{ $n(data[indextr].December, !showMarginYear ? 'currency' : 'percent') }} </vs-td>
                                <vs-td :data="data[indextr].TOTAL">    {{ $n(data[indextr].TOTAL   , !showMarginYear ? 'currency' : 'percent') }} </vs-td>
                            
                            </vs-tr>
                        </template>
                    </template>
                </vs-table>
            </div>           
            </div>
        </div>  

        <modal :show="modalShow" :dark="true" title="Código Acceso">
            <div slot="modal-body">
                <div class="modal-content" style="padding: 35px 35px 15px">
                    <p>Por favor ingresar el código de acceso</p>
                    <br>
                    <vs-input v-model="codeAccess"  :danger="errorCode" danger-text="Código incorrecto" v-on:keyup.enter="validAccess()"/>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="primary" type="border" @click="validAccess()" :disabled="disabled">Ingresar</vs-button>
                </div>
            </div>
        </modal>

    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import CatalogService from '@/services/CatalogService'
import BookingService from '@/services/BookingService'
import modal from "@/components/Modals/Basic.vue";
import searchSupplier from '@/components/findSupplier.vue'
const  { mapActions: mapAppSettingActions } = createNamespacedHelpers('appSetting')


import lineChart  from '@/components/Charts/Line2'
import barChart  from '@/components/Charts/Bar'

export default {
    name: "BookingsView",
    components: {
        modal,
        lineChart,
        barChart,
        searchSupplier
    },
    data () {
        return {
            key: 1,
            date: "",
            modalShow: true,
            codeAccess: "",
            errorCode: false,
            disabled: false,
            monthAbr: ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
            salesDirectIndirect: [],
            statsDirectIndirect: [],
            statsValuesDirectIndirect: [],
            statsLabelsDirectIndirect: [],

            month: 0,
            year: [],
            yearOptions: [],
            monthOptions: ['Todos','Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre',],
            kpis: [],
            arrYear: {
                labels: [],
                values: []
            },
            arrLabel: {
                labels: [],
                values: []
            },
            comparative: {

                labels: [],
                values: []
            },
            showLineChart: false,
            showLineChartIndirect: true,
            showLineChartDirect: true,
            colors: ["#00ccb3","#1a75d6","#fac71e","#905bff","#f9893e","#fc573b","#df41b6"],
            kpisDirect: [],
            salesDirect:  {
                labels: [],
                values: []
            },
            kpisIndirect: [],
            salesIndirect:  {
                labels: [],
                values: []
            },
            totalDirect: 0,
            totalIndirect: 0,
            totalSales: 0,
            suppliers: [],
            openSuggestions: true,
            consultants:[],
            vendors:[],
            kpisData: {
                retail: [],
                margin: []
            },
            showMarginYear: false,
            dateFilter: "travelDate",
            dateTypes: [{ code: "travelDate", name:"Travel Date"}, {code: "sellDate", name: "Sell Date"}]
            
        }
    },
    watch: {
        showMarginYear( val ){
            this.orderKpis( val ? 'margin' : 'retail');
        }
    },
    async created () {
        let code =  JSON.parse(localStorage.getItem('CDG'));
        this.codeAccess = code
        if(this.codeAccess != "" || this.disabled != undefined){
            this.validAccess();
        }
        
        for (let i = new Date().getFullYear(); i > new Date().getFullYear() - 20; i--) {
            this.yearOptions.push(i);
        }

        this.filterDashboard();
        this.getConsultants();
        // this.loadingCharts()

         
    },
    directives: {
        'click-outside': {
            bind: function (el, binding, vnode) {
                console.log(el)
                el.clickOutsideEvent = (event) => {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.clickOutsideEvent);
            }
        }
    },
    methods: {
        ...mapAppSettingActions(["LOADING_STATUS"]),
        async filterDashboard() {
            this.getKpisByYear();
            this.getDirectSales();
            this.getIndirectSales();
        },
        async getConsultants(){
            this.consultants = await CatalogService.getConsultants({});         
        },
        formatMonthAmounts(data){
            let jan = data.January  != null ? data.January : 0;
            let feb = data.February != null ? data.February : 0;
            let mar = data.March != null ? data.March : 0;
            let apr = data.April != null ? data.April : 0;
            let may = data.May   != null ? data.May : 0;
            let jun = data.June  != null ? data.June : 0;
            let jul = data.July  != null ? data.July : 0;
            let aug = data.August != null ? data.August : 0;
            let sep = data.September != null ? data.September : 0;
            let oct = data.October   != null ? data.October : 0;
            let nov = data.November  != null ? data.November : 0;
            let dec = data.December   != null ? data.December : 0;
            return [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec];
        },
        filterAgenciesCodes () {
            let arr = [];
            this.suppliers.forEach( item => {
                arr.push(item.CODE)
            })
            return arr;
        },
        filterVendorsCodes () {
            let arr = [];
            this.vendors.forEach( item => {
                arr.push(item.INITIALS)
            })
            return arr;
        },

        async getKpisByYear(){
            let response = await BookingService.getKpisByYear({ 
                    groupBy: "YEAR", 
                    dateFilter: this.dateFilter,
                    saleYears: this.year, 
                    consultants: this.filterVendorsCodes(this.vendors),
                    agencies: this.filterAgenciesCodes(this.suppliers)
                });            
            if((response.status/100) == 2){
                this.kpis = response.data.data
                let orderKpi = this.groupBy(this.kpis, 'DATA_TYPE');
                this.kpisData.retail = orderKpi.RETAIL;
                this.kpisData.margin = orderKpi.MARGIN;
                console.log('GROUP', )
                this.orderKpis('retail');
            }
        },
        orderKpis(options) {
            this.kpis = this.kpisData[options];
            let tempArrValues = []
            let counter = 0;    
            if( !this.showMarginYear){
                this.totalSales = 0;
            }
            this.kpis.forEach(item => {
                this.comparative.labels.push(item.YEAR)
                tempArrValues.push(
                    {
                        label: item.YEAR,
                        backgroundColors: this.colors[counter],
                        data: this.formatMonthAmounts(item)
                    }
                )
                if( !this.showMarginYear){
                    this.totalSales = this.totalSales + item.TOTAL
                } else {
                    // Margin
                    item.April     = item.April != null ? (item.April / 100) : 0;
                    item.August    = item.August != null ? (item.August / 100) : 0;
                    item.December  = item.December != null ? (item.December / 100) : 0;
                    item.February  = item.February != null ? (item.February / 100) : 0;
                    item.January   = item.January != null ? (item.January / 100) : 0;
                    item.July      = item.July != null ? (item.July / 100) : 0;
                    item.June      = item.June != null ? (item.June / 100) : 0;
                    item.March     = item.March != null ? (item.March / 100) : 0;
                    item.May       = item.May != null ? (item.May / 100) : 0;
                    item.November  = item.November != null ? (item.November / 100) : 0;
                    item.October   = item.October != null ? (item.October / 100) : 0;
                    item.September = item.September != null ? (item.September / 100) : 0;

                }
                counter > 6 ? counter = 0 : counter++
            });
            this.comparative.values = tempArrValues
            // this.key++;
        },

        async getDirectSales(){
            let response = await BookingService.getKpisByYear({ 
                groupBy: "YEAR", 
                saleYears: this.year, 
                consultants: this.filterVendorsCodes(this.vendors),
                agencies: this.filterAgenciesCodes(this.suppliers),
                saleType: 'D',

            }); 
            if((response.status/100) == 2){
                this.kpisDirect = response.data.data
                let tempArrValues = []
                let counter = 0;
                this.totalDirect = 0;
                this.kpisDirect.forEach(item => {
                    // console.log({ josue: item.YEAR})
                    this.comparative.labels.push(item.YEAR)

                    tempArrValues.push(
                        {
                            label: item.YEAR,
                            backgroundColors: this.colors[counter],
                            data: this.formatMonthAmounts(item)
                        }
                    )
                    
                    this.totalDirect = this.totalDirect + item.TOTAL
                    counter > 6 ? counter = 0 : counter++

                });
                this.salesDirect.values = tempArrValues
                this.key++;
            }
        },
        async getIndirectSales(){
            let response = await BookingService.getKpisByYear({ 
                groupBy: "YEAR", 
                saleYears: this.year, 
                consultants: this.filterVendorsCodes(this.vendors),
                agencies: this.filterAgenciesCodes(this.suppliers),
                saleType: 'I',

            }); 
            if((response.status/100) == 2){
                this.kpisIndirect = response.data.data
                let tempArrValues = []
                let counter = 0;
                this.totalIndirect = 0;
                this.kpisIndirect.forEach(item => {
                    // console.log({ josue: item.YEAR})
                    this.comparative.labels.push(item.YEAR)

                    tempArrValues.push(
                        {
                            label: item.YEAR,
                            backgroundColors: this.colors[counter],
                            data: this.formatMonthAmounts(item)
                        }
                    )
                    
                    this.totalIndirect = this.totalIndirect + item.TOTAL
                    counter > 6 ? counter = 0 : counter++

                });
                this.salesIndirect.values = tempArrValues
                this.key++;
            }
        },
        validAccess() {
            if(this.codeAccess == "asuaire2022") {
                this.modalShow = false
                this.status = "done"
                localStorage.setItem('CDG', JSON.stringify(this.codeAccess))
            } else {
                this.errorCode = true
            }
        },
        findSupplier(response) {
            if(this.suppliers.findIndex(item => item.CODE == response.CODE) < 0){
                this.suppliers.push(response)
            }
        },
        removeSupplier(item) {
            this.suppliers.splice(this.suppliers.indexOf(item), 1)
        },
        closeSuggest(){
            this.openSuggestions = !this.openSuggestions
        },
        groupBy (array, key){
            // Return the end result
            return array.reduce((result, currentValue) => {
                // If an array already present for key, push it to the array. Else create an array and push the object
                (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
                );
                // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                return result;
            }, {}); // empty object is the initial value for result object
         },
        
    }
}
</script>
<style scoped>
.subtitle{
    display:  flex;
    justify-content: flex-end;
    align-items: center;
}
</style>